<template>
    <div>
        <v-card tile class="py-12" light :img="require('@/assets/fond-histoire.jpg')">
            <v-container fluid class="fill-height">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="6">
                        <v-card flat color="transparent">
                        <v-card-title  style="font-family: Abril Fatface !important;" class="headline primary--text" >
                            <h1 style="font-family: Abril Fatface !important;"  class="headline primary--text">NOTRE HISTOIRE</h1>
                        </v-card-title>
                        <v-card-text class="text-justify">
                            <span>
                                C’est en 1982 que j’obtiens mon CAP cuisine à l’Ecole Hôtelière de la Chaise Dieu . Je décide de partir
                                travailler sur Paris où je fais mes armes chez un traiteur organisateur de réceptions . En 1989 je crée mon
                                entreprise à Beauchamp (95) « Alliance réceptions » traiteur et organisateur de réceptions réalisant des
                                prestations de 10 à 1500 personnes. Je cède cette entreprise en 2003 .<br/>
                                Fin des années 90, je crée et dirige un centre de formation professionnelle pour CAP cuisine et service en
                                salle et ouvre un département informatique bureautique et infographiste.<br/>
                                Tout en maintenant l’activité de traiteur et formation, je développe commercialement un logiciel dédié aux
                                métiers de bouche.<br/>
                                L’exploitation de ce logiciel me permet des années plus tard, de travailler pour le compte du catering aérien
                                dont le concept est de faire de la restauration haut de gamme pour l’aviation privée. Dans un premier temps
                                embauché sur l’aéroport de Nice auprès du premier caterer aérien privé , je me retrouve au bout de 5 ans
                                directeur et chef exécutif dans le premier caterer Américain sur le site du Bourget.<br/>
                                Après huit années en catering aérien, je décide de me lancer dans la conception de mon food truck et achète
                                la remorque « la knett » que je réaménage et restaure en totalité . Parallèlement je suis des formations en
                                brasserie et me lance dans le brassage de ma bière la « knett maltée » et la « bul’k ».
                                Aujourd’hui installé ma brasserie est installée à Montfaucon, à coté de laquelle je vous propose un nouveau snack fixe de street food Américain<br/>
                                La remorque Knett est proposée pour des prestations privées en entreprises ou familiales ou d’autres
                                manifestations telles que foires comme nous l’avons déjà fait à plusieurs reprises.<br/>
                            </span>
                        </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-img :src="require('@/assets/knett-picto-histoire.png')" height="auto" width="auto" contain />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
export default {
    name : 'History',
    data() {
        return {
           
        }
    }
}
</script>