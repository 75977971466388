<template>
<div>
<app-toolbar/>
   <v-content class="mb-12 pb-12">
       <div class="mb-12 mb-md-0">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center"> 
                <v-col cols="12">
                    <v-card>
                    <v-img height="300px" :src="require('@/assets/slider-knett-bieres.jpg')">  
                        <v-container class="fill-height" fluid>
                            <v-row align="center" justify="center"> 
                                <v-flex shrink>
                                     <h1 style="font-family: Abril Fatface !important; text-shadow: 3px 3px 2px rgba(255,255,255,0.8)" 
                            :class="$vuetify.breakpoint.name=='xs'? 'display-1 font-weight-bold primary--text' : ( $vuetify.breakpoint.name=='sm'? 'display-1 font-weight-bold  primary--text' : 'display-3 font-weight-bold  primary--text' ) "
                            >LA K-NETT MALTÉE</h1>
                                </v-flex>
                            </v-row>
                        </v-container>
                    </v-img>
                    </v-card>
                </v-col>
                <v-col class="text-center" cols="12" md="8">
                    <v-row>
                        <div class="d-flex flex-column align-center py-4" style="width:100%">
                            <span class="headline text-center">Découvrez nos bières originales et savoureuses.</span>
                            <br/>
                            <span class="text-center">
                                Toutes nos bières sont de fermentation haute, elles sont ainsi refermentées en bouteille et sont non filtrées, non stérilisées.
                                <br/>
                                Veillez à les conserver verticalement.
                            </span>
                            </div>
                    </v-row>
                    <v-row >
                        <v-col v-for="biere in bieres" :key="biere.id" cols=12 sm="6" md="3">
                            <v-card color="transparent" flat class="pa-2">
                                <v-img class="my-2" max-height="150px" contain :src="conf.api_url+biere.icon_url" />
                                <span style="font-family: Abril Fatface !important;" 
                                :class="biere.categorie=='BLONDE'? 'yellow--text text--lighten-2 headline' : 
                                biere.categorie=='ROUSSE'? 'orange--text text--darken-1 headline' : biere.categorie=='BLANCHE'? 'white--text headline' : 'brown--text headline'">
                                    {{biere.nom}}
                                </span>
                                <v-divider color="white" class="my-1" />
                                <span class="headline" v-if="biere.prix33!=0">33 cl</span>
                                <v-sheet tile color="primary" v-if="biere.prix33!=0">
                                    <span class="headline">{{biere.prix33}} €</span>
                                    <v-divider color="white" />
                                </v-sheet>
                                <span class="headline" v-if="biere.prix50!=0">50 cl</span>
                                <v-sheet tile color="primary" v-if="biere.prix50!=0">
                                    <span class="headline">{{biere.prix50}} €</span>
                                    <v-divider color="white" />
                                </v-sheet>
                                <span class="headline" v-if="biere.prix75!=0">75 cl</span>
                                <v-sheet tile color="primary" v-if="biere.prix75!=0">
                                    <span class="headline">{{biere.prix75}} €</span>
                                    <v-divider color="white" />
                                </v-sheet>
                                <p v-if="biere.gout!=''" class="text-left mt-2">Gout: {{biere.gout}} </p>
                                <p v-if="biere.composition!=''" class="text-left">Composition: {{biere.composition}} </p>
                                <p v-if="biere.alc!=''" class="text-left">Alc: {{biere.alc}}% vol. </p>
                                <p v-if="biere.alergenes!=''" class="text-left">Allergènes: {{biere.alergenes}} </p>
                            </v-card>
                        </v-col>
                    </v-row>
                     <v-row v-if="bulk.length>0" class="d-flex justify-center">
                        <span class="display-1 grey--text text--lighten-2" style="font-family: Abril Fatface !important;" >
                            LA KNETT MALTÉE C'EST AUSSI NOS BUL'K AUX FRUITS...
                        </span>
                    </v-row>
                    <v-row >
                        <v-col v-for="biere in bulk" :key="biere.id" cols=12 sm="6" md="3">
                            <v-card color="transparent" flat class="pa-2">
                                <v-img class="my-2" max-height="150px" contain :src="conf.api_url+biere.icon_url" />
                                <span style="font-family: Abril Fatface !important;" 
                                class="white--text headline">
                                    {{biere.nom}}
                                </span>
                                <v-divider color="white" class="my-1" />
                                <span class="headline" v-if="biere.prix33!=0">33 cl</span>
                                <v-sheet tile color="primary" v-if="biere.prix33!=0">
                                    <span class="headline">{{biere.prix33}} €</span>
                                    <v-divider color="white" />
                                </v-sheet>
                                <span class="headline" v-if="biere.prix50!=0">50 cl</span>
                                <v-sheet tile color="primary" v-if="biere.prix50!=0">
                                    <span class="headline">{{biere.prix50}} €</span>
                                    <v-divider color="white" />
                                </v-sheet>
                                <span class="headline" v-if="biere.prix75!=0">75 cl</span>
                                <v-sheet tile color="primary" v-if="biere.prix75!=0">
                                    <span class="headline">{{biere.prix75}} €</span>
                                    <v-divider color="white" />
                                </v-sheet>
                                <p v-if="biere.gout!=''" class="text-left mt-2">Gout: {{biere.gout}} </p>
                                <p v-if="biere.composition!=''" class="text-left">Composition: {{biere.composition}} </p>
                                <p v-if="biere.alc!=''" class="text-left">Alc: {{biere.alc}}% vol. </p>
                                <p v-if="biere.alergenes!=''" class="text-left">Allergènes: {{biere.alergenes}} </p>
                            </v-card>
                        </v-col>
                    </v-row>                    
                </v-col>
            </v-row>
        </v-container>
       </div>
   </v-content>
   <app-footer class="mt-12"/>
</div>
</template>
<script>
import Toolbar from '@/components/comps/Toolbar.vue'
import Footer from '@/components/comps/Footer.vue'

import config from '@/assets/config.json'
import axios from 'axios'

export default {
    components: {
      'app-toolbar' : Toolbar,
      'app-footer' : Footer
    },
    name : 'Maltee',
    created() {     
        axios({
                method: 'get',
                url: config.api_url+'/boissons'
            }).then(response => {
            var boissons=response.data;
            boissons.forEach(boisson => {
                if(boisson.categorie=="BULK"){
                    this.bulk.push(boisson);
                }else{
                    this.bieres.push(boisson);
                }
                this.bieres=this.bieres.sort((a,b)=>{
                    return a.nom.localeCompare(b.nom);
                })
                this.bulk=this.bulk.sort((a,b)=>{
                    return a.nom.localeCompare(b.nom);
                })
            });
        });
    },
    data() {
        return{
            bulk:[],
            bieres: [],
            conf: config
        }
    }
}
</script>