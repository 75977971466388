<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
.damier {
   margin: auto;
    max-height: 100%;
    width: 100%;
    height: 3em;
    background-color: #7f8fa6 !important;
    background-image: linear-gradient(45deg, #e00285 25%, transparent 25%, transparent 75%, #e00285 75%), linear-gradient(45deg, #e00285 25%, transparent 25%, transparent 75%, #e00285 75%);
    background-size: 3em 3em;
    background-position: 0 0, 1.5em 1.5em;
    background-repeat: repeat;
}

.bar-damier {    
    max-width: inherit;
    height: 35px;
    background-image: linear-gradient(45deg, #e00285 25%, transparent 25%, transparent 75%, #e00285 75%), linear-gradient(45deg, #e00285 25%, transparent 25%, transparent 75%, #e00285 75%);
    background-size: 35px 35px;
    background-position: 0 0, 17.5px 17.5px;
    margin: auto;
    max-height: 100%;
    width: 100%;
    background-color: #7f8fa6 !important;
    background-repeat: repeat;
}
</style>