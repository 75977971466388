<template>
<div>
    <div class="damier">
        <v-img v-if="type=='mois'" class="mx-4" style="z-index: 1;" :src="require('@/assets/kanettes-pictos-knett-du-mois.png')"
        contain height="120" position="left"/>
         <v-img v-if="type=='menu'" class="mx-4" style="z-index: 1;" :src="require('@/assets/kanettes-pictos-carte.png')"
        contain height="120" position="left"/>
    </div>
    <v-card color="transparent" flat max-width="800px">
        <v-row align="start" justify="start"> 
                <v-col xs="12" md="4" class="my-0 py-0 mx-0 pr-md-0">
                    <v-img class="mx-2 pa-0 pt-12" 
                    :height="$vuetify.breakpoint.name=='xs'? '220px' : ( $vuetify.breakpoint.name=='sm'? '220px' : '500px' ) " :src="require('@/assets/hamburger-carte-generale.jpg')" >
                        <h1 style="font-family: Abril Fatface !important; text-shadow: 2px 2px 1px rgba(0,0,0,0.8)"
                        class="text-right display-1 font-weight-bold mt-12 white--text mx-2">
                            {{title}}
                        </h1>
                        <div  style="text-shadow: 0px 0px 4px rgba(0,0,0,1)" class="text-right subtitle mt-2 white--text mx-2">{{subtitle}}</div>
                    </v-img>
                </v-col>
                <v-col xs="12" md="8" class="my-0 py-0 mx-0 pl-md-0">
                    <div v-if="type=='mois'">
                        <v-card class="display-2 font-weight-bold">
                            Yup
                        </v-card>
                    </div>
                    <div v-if="type=='menu'">
                        <v-card style="z-index : 2;" flat height="500px" color="white">
                            <v-tabs
                            v-model="menuTab"
                            background-color="black"
                            dark
                            show-arrows
                            centered
                            :height="$vuetify.breakpoint.name=='xs'? '100px' : ( $vuetify.breakpoint.name=='sm'? '100px' : '500px' ) "
                            grow
                            icons-and-text
                            :vertical="$vuetify.breakpoint.name=='xs'? false : ( $vuetify.breakpoint.name=='sm'? false : true ) "
                            >
                                <v-tab
                                    v-for="groupecategorie in groupescategories"
                                    :key="groupecategorie.id"
                                    
                                    
                                >
                                    <v-img :src="conf.api_url+groupecategorie.icon_url" height="40px" contain max-width="40px"/>                                
                                
                                </v-tab>
                                <v-tab-item
                                    v-for="groupecategorie in groupescategories"
                                    :key="groupecategorie.id"
                                >
                                    <v-card flat :max-height="$vuetify.breakpoint.name=='xs'? '400px' : ( $vuetify.breakpoint.name=='sm'? '400px' : '500px' )" color="white" style="overflow-y: auto">
                                        <v-card-text class="primary--text text-center text-uppercase font-weight-medium">
                                            <h2 class="text-center headline" style="font-family: Abril Fatface !important;" >{{ groupecategorie.nom }}</h2>
                                        </v-card-text>
                                        <v-list disabled light>

                                            <div v-for="produit in produits" :key="produit.id">
                                                
                                                <v-list-item v-if="produit.categorie.groupe.id==groupescategories[menuTab].id">
                                                    <v-list-item-avatar tile>
                                                        <v-img contain :src="conf.api_url+produit.categorie.icon_url"/>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-row>
                                                            <v-col cols="9" class="text-left">
                                                                <span class="font-weight-medium text-uppercase">{{produit.nom}}</span><br/>
                                                                <span class="font-italic" v-if="produit.description!=''">{{produit.description}}</span><br v-if="produit.description!=''"/><br/>
                                                                <span class="grey--text">Ingredients : {{produit.ingredients}}</span><br/>
                                                                <span>Allergènes majeurs : {{produit.alergenes}}</span>
                                                            </v-col>
                                                            <v-col cols="3">
                                                                <v-chip color="lightgrey">{{produit.prix.substr(-2)=="00"? produit.prix.substr(0,produit.prix.length-3) : 
                                                                            produit.prix.substr(-1)=="0"? produit.prix.substr(0,produit.prix.length-1) :
                                                                                produit.prix }}€
                                                                </v-chip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider v-if="produit.categorie.groupe.id==groupescategories[menuTab].id"/>
                                            </div>
                                        </v-list>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>                          
                        </v-card>                   
                    </div>

                </v-col>
        </v-row> 

    </v-card>
    <div class="damier">
    </div>
</div> 
</template>
<script>
import config from '@/assets/config.json'
export default {
    name : 'Tile',
    props: ['title','subtitle','type','produits','categories','groupescategories'],
    
    data() {
        return {
            menuTab: 0,
            conf: config
        }
    }
}
</script>