<template>
<div>
<app-toolbar/>
   <v-content>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center"> 
                <v-col class="text-center" cols="12">
                    <h1 style="font-family: Abril Fatface !important;" class="primary--text display-2">
                        Mentions légales
                    </h1><br/><br/>
                    <span class="text-center">
            Le site La K-nett est édité par ALLIANCE-SOFT, SARL au capital de 7623,44€ SIRET 42182372500054 / Code NAF 5610c. Adresse : 4bis Avenue du 19 mars 1962, 43290 Montfaucon-en-Velay - France
            <br/><br/>
            Responsable de la publication : Thierry Fournier
            <br/><br/>
            Web-designer : Franck Perrot Design. Tél. : 06 75 05 04 26 – contact@fperrotdesign.fr
            <br/><br/>
            Implémentation du site : VueJS + Vuetify par Romain Fournier
            <br/><br/>
            Hébergement : OVH 2 rue kellermann BP 80157 59053 ROUBAIX CEDEX 1 – France Service client : 1007
            <br/><br/>
            Photos : Pixhere.com et Thierry Fournier / illustrations : Franck Perrot Design
                    </span>
                </v-col>
            </v-row>
        </v-container>
   </v-content>
   <app-footer/>
</div>
</template>
<script>
import Toolbar from '@/components/comps/Toolbar.vue'
import Footer from '@/components/comps/Footer.vue'
export default {
    components: {
      'app-toolbar': Toolbar,
      'app-footer' : Footer
    },
    name : 'Mentions'
}
</script>