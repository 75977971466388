<template>
    <div>
        <v-parallax 
        :src="require('@/assets/roulotte-la-k-nett-action-cuisine.jpg')"
        class="px-0 px-md-8">
            <v-container fluid class="fill-height">
                <v-row justify="center" align="center">
                        <v-card class="px-0 px-md-12" color="rgba(0,0,0,0.5)"
                        
                        :max-width="$vuetify.breakpoint.name=='xs'? '100%' : ($vuetify.breakpoint.name=='sm'? '100%' : '70%' ) ">
                            <v-card-title >
                                
                            </v-card-title>
                            <v-card-text>
                                <h1 class="title text-uppercase white--text">la K-nett</h1> <br/>
                                <div :class="$vuetify.breakpoint.name=='xs'? 'display-1 white--text' : ( $vuetify.breakpoint.name=='sm'? 'display-1 white--text' : 'display-2 white--text' ) "
                                style="font-family: Abril Fatface !important;">En mode "privatisation"</div><br>
                                <span class="white--text"> Faites venir la K-nett et son cuisinier sur vos événements !<br/> 
                                Menu sur mesure<br/>
                                Plus d'info : <strong><a :href="'tel:'+conf.contact.phone" style="text-decoration: none">{{conf.contact.phone}}</a></strong>
                                </span>
                            </v-card-text>
                        </v-card>
                </v-row>
            </v-container>
        </v-parallax>
    </div>
</template>
<script>
import config from '@/assets/config.json'
export default {
    name : 'Privatisation',
    data() {
        return {
            conf: config
           
        }
    }
}
</script>