<template>
    <v-carousel cycle 
    :height="$vuetify.breakpoint.name=='xs'? '220px' : ( $vuetify.breakpoint.name=='sm'? '220px' : '500px' ) ">
        <v-carousel-item v-for="(item,i) in slides" :key="i" :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
            >
             <v-container class="fill-height" fluid>
                <v-row align="center" justify="center"> 
                    <v-flex shrink>
                        <v-img :src="require('@/assets/kanette-du-mois-pictos.png')"
                        contain
                        :height="$vuetify.breakpoint.name=='xs'? '50px' : ( $vuetify.breakpoint.name=='sm'? '50px' : '150px' ) "
                        />
                    </v-flex>
                    <v-flex shrink lazy>
                        <div class=" my-4 ">
                            <span style="font-family: Abril Fatface !important; text-shadow: 3px 3px 2px rgba(0,0,0,0.8)" 
                            :class="$vuetify.breakpoint.name=='xs'? 'display-1 font-weight-bold' : ( $vuetify.breakpoint.name=='sm'? 'display-1 font-weight-bold' : 'display-2 font-weight-bold' ) "
                            >FOOD-TRUCK</span>
                        </div>  
                        <v-chip class="headline font-weight-medium" style="border: solid; border-color: white; background-color: black" large >
                            <a :href="'tel:'+conf.contact.phone" style="text-decoration: none; color: white;"><v-avatar left><v-icon>mdi-phone</v-icon></v-avatar>
                            {{conf.contact.phone}}</a>
                        </v-chip>
                    </v-flex>
                </v-row>
             </v-container>
            </v-carousel-item>
        </v-carousel>
</template>
<script>
import config from '@/assets/config.json'
export default {
    name : 'Carousel',
    data() {
        return {
            slides: [
                {
                    src: require('@/assets/slider-accueil-knett-bagel.jpg')
                },
                {
                    src: require('@/assets/slider-accueil-knett-hamburger.jpg')
                },
                {
                    src: require('@/assets/slider-accueil-knett-roulotte.jpg')
                },
                {
                    src: require('@/assets/slider-knett-bieres.jpg')
                }
                
            ],
            conf: config
        }
    }
}
</script>