<template>
    <div>
        
        <app-toolbar/>  
        <v-content>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center"> 
                    <v-col class="text-center" cols="12">
                        <v-card class="mb-12">  
                            <app-carousel/>             
                        </v-card>
                        <!-- v-card light  max-width="800px" class="my-12 mx-auto" large flat>
                            <app-tile title="LA K-NETT DU MOIS" type="mois"/>
                        </v-card -->
                        <v-card light  max-width="800px" class="my-12 mx-auto" large flat>
                            <app-tile title="NOTRE CARTE GÉNÉRALE" subtitle="sur commande" type="menu" 
                            :produits=this.produits :groupescategories=this.groupesCategories :categories=this.categories />
                        </v-card>
                        <div class="my-12 py-12">
                            <app-parallax/>
                        </div>
                        <div class="mt-12 pt-12">
                            
                            <app-history/>
                        </div>
                        <div class="mb-8 pb-8">
                            <app-commande/>
                        </div>
                        
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
        <app-footer/>
    </div>
</template>
<script>
import Toolbar from '@/components/comps/Toolbar.vue'
import Carousel from '@/components/comps/Carousel.vue'
import Tile from '@/components/comps/Tile.vue'
import Privatisation from '@/components/comps/Privatisation.vue'
import History from '@/components/comps/History.vue'
import Commande from '@/components/comps/Commande.vue'
import Footer from '@/components/comps/Footer.vue'

import config from '@/assets/config.json'
import axios from 'axios'

export default {
    components: {
      'app-toolbar': Toolbar,
      'app-carousel' : Carousel,
      'app-tile' : Tile,
      'app-parallax' : Privatisation,
      'app-history' : History,
      'app-commande' : Commande,
      'app-footer' : Footer
    },
    name : 'Accueil',
    created() {     
        axios({
            method:'get',
            url: config.api_url+'/produits'
        }).then(response => {
            this.produits=response.data
            axios({
                method:'get',
                url: config.api_url+'/categories'
            }).then(response => {
                this.categories=response.data
                axios({
                    method: 'get',
                    url: config.api_url+'/groupescategories'
                }).then(response => {
                    this.groupesCategories=response.data
                })
            })
        })
    
    },
     data() {
        return {
            slides: [
                {
                    src: require('@/assets/slider-accueil-knett-bagel.jpg')
                },
                {
                    src: require('@/assets/slider-accueil-knett-hamburger.jpg')
                },
                {
                    src: require('@/assets/slider-accueil-knett-roulotte.jpg')
                },
            ],
            produits: null,
            categories: null,
            groupesCategories: null
        }
    }
}
</script>