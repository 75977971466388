<template>
<div>

<v-navigation-drawer v-model="drawer" app >
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-flex px-4>
            <v-list-item-title class="title">
              Menu
            </v-list-item-title>
            <v-list-item-subtitle>
              La K-nett Food Truck
            </v-list-item-subtitle>
              </v-flex>
              <v-flex>
                <v-icon @click="drawer=false">mdi-close</v-icon>
              </v-flex>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link v-for="link in links" :key="link.nom" :to="link.to" >
          <v-list-item-content>
            <v-list-item-title>{{link.nom}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item link :href="webshopUrl">
          <v-list-item-content>
            <v-list-item-title>Commander en ligne</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="bar-damier">
        </div>
    <v-app-bar hide-on-scroll
       color="rgba(102, 11, 94,0.7)">
      <div class="hidden-md-and-up"> 
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      </div>
        <v-container mx-0 py-0>
          <v-layout>
            <router-link to='/'>
              <v-img :src="require('@/assets/cropped-logo-K-NETT.png')"
            class="mr-5"
            contain
            height="48"
              />
            </router-link>
              <v-spacer/>
              <v-btn  dark v-for="link in links" :key="link.nom" :to="link.to" class="mt-2 hidden-sm-and-down" text>
                  {{ link.nom }}
              </v-btn>
               <v-btn light raised :href="webshopUrl" target="_blank" rel="noreferrer noopener" class="mt-2 hidden-sm-and-down">
                  Commander en ligne
              </v-btn>
          </v-layout>
        </v-container>
      </v-app-bar>

</div>
</template>

<script>

import config from '@/assets/config.json'

export default {
    name: "Toolbar",
    data() {
        return {
            links: [
                {
                    nom: 'Accueil',
                    to: '/'
                },
                {
                    nom: 'La K-nett maltée',
                    to: '/knett-maltee'
                },
                {
                    nom: 'Galerie',
                    to: '/galerie'
                },
                {
                    'nom': 'Nous situer - Contact',
                    'to': '/contact'
                },
            ],
            collapseOnScroll : true,
            drawer:false,
            webshopUrl: config.webshop        }
    }
}

</script>
