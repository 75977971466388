<template>
        <v-footer absolute color="primary" class="pa-0 ">
                <v-row no-gutters justify="center">
                    <v-col cols=12  class="py-1 secondary">
                        <v-row justify="center" align="center" class="mr-0">
                            <v-flex shrink>
                                <span class="primary--text"> Rejoignez nous sur : </span>
                            </v-flex>
                            <v-flex shrink>
                                <a :href="conf.contact.fb" target="_blank">
                                    <v-avatar tile class="mx-4" >
                                        <v-img contain :src="require('@/assets/facebook-logo.jpg')" />
                                    </v-avatar>
                                </a>
                            </v-flex>
                        </v-row>
                    </v-col>
                    <v-col cols=12 class="primary py-4">
                        <v-row justify="center" align="center" class="mr-0 font-weight-light">
                            <v-flex shrink class="mx-2">
                                La Knett 2019-2020
                            </v-flex>
                            <v-divider class="my-1" color="white" vertical/>
                            <v-flex shrink class="mx-2">
                                <router-link to="/mentions">
                                <span  class="white--text">
                                    Mentions légales
                                </span>
                                </router-link>
                            </v-flex>
                            <v-divider class="my-1" color="white" vertical />
                            <v-flex shrink class="mx-2">
                                <a href="https://fperrotdesign.fr/">
                                <span class="white--text"> Webdesign : Franck Perrot Design</span>
                                <v-avatar size="36"><v-img contain :src="require('@/assets/logo-fperrotdesign.png')"/></v-avatar>
                                </a>
                            </v-flex>
                            <v-divider class="my-1" color="white" vertical />
                            <v-flex shrink class="mx-2">
                                Réalisation du site : Romain Fournier
                            </v-flex>
                        </v-row>
                    </v-col>
                </v-row>
        </v-footer>

</template>
<script>
import config from "@/assets/config.json"
export default {
    name : 'Footer',
    data() {
        return {
            conf: config
           
        }
    }
}
</script>