<template>
    <div>
        <v-card flat class="py-12" color="#121212">
            <v-container fluid class="fill-height">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="6">
                        <v-card flat color="transparent">
                        <v-card-text class="text-center" >
                            <h1 class="display-2 white--text" style="font-family: Abril Fatface !important;"> PASSER COMMANDE</h1><br/><br/>
                            <a :href="'tel:'+conf.contact.phone" style="text-decoration: none" 
                            :class="$vuetify.breakpoint.name=='xs'? 'display-1 white--text' : ( $vuetify.breakpoint.name=='sm'? 'display-1 white--text' : 'display-2 white--text' ) "
                            >{{conf.contact.phone}}</a>
                        </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import config from "@/assets/config.json"
export default {
    name : 'Commande',
    data() {
        return {
            conf: config
           
        }
    }
}
</script>