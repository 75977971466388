import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
    rtl: false,
    theme: {
        dark: true,
        themes: {
            dark: {
                primary : '#E01485',
                secondary: "#121212"
            }
        }
            
    }

});
