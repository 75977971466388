import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/pages/Home.vue'
import Mentions from '@/components/pages/Mentions.vue'
import Maltee from '@/components/pages/Maltee.vue'
import Contact from '@/components/pages/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/mentions',
    name: 'Mentions Légales',
    component: Mentions
  },
  {
    path: '/knett-maltee',
    name: 'La K-nett Maltée',
    component: Maltee
  },
  {
    path: '/galerie',
    name: 'Galerie',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/pages/Galery.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
