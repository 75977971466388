<template>
<div>
<app-toolbar/>
   <v-content class="mb-12 pb-12 ">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center"> 
                <v-col class="text-center" cols="12" md="8">
                    <h1 style="font-family: Abril Fatface !important;" class="primary--text display-2">
                        Contact
                    </h1>
                    <v-divider color="white" class="my-8"/>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="2">
                            <v-img :src="require('@/assets/kanettes-pictos-knett-du-mois.png')" max-height="150px" contain />
                        </v-col>
                        <v-col cols="12" md="10" class="text-justify">
                            <h2 class="headline">Vous souhaitez contacter la Knett ?</h2>
                            <p>N’hésitez pas à nous laisser un message par télephone ou par e-mail pour nous donner votre avis, 
                                demander un renseignement ou encore passer commande en utilisant 
                                notre numéro de téléphone : <a :href="'tel:'+conf.contact.phone" style="text-decoration: none">{{conf.contact.phone}}. </a>
                                Nous nous ferons un plaisir de vous répondre le plus rapidement possible.
                            </p>
                            <p><em>Toute l’équipe de la Knett vous souhaite un bon appétit et vous salue bien.
                                </em></p>
                        </v-col>
                    </v-row>
                    <v-divider color="white" class="my-8"/>
                    <v-row align="center" justify="center">
                        <v-col cols="12" md="6">
                            <v-card flat light :height="$vuetify.breakpoint.name=='xs'? 'auto' : ( $vuetify.breakpoint.name=='sm'? 'auto' : '500px' )" style="overflow-y: auto">
                                <v-card-title><h2 style="font-family: Abril Fatface !important;" class="primary--text headline">COORDONNÉES</h2></v-card-title>
                                <v-divider class="my-2 mx-4" color="pink"/>
                                <v-card-text>
                                    
                                            <v-row v-for="(contact, i) in contacts" :key=i align="center" justify="start">
                                                <v-col cols="2" class="px-2"><v-avatar color="primary"><v-icon dark>{{contact.icon}}</v-icon></v-avatar></v-col>
                                                <v-col cols="10" class="px-2 text-left">
                                                    <a v-if="contact.type==='phone'" :href="'tel:'+contact.value">
                                                        {{contact.value}}
                                                    </a>
                                                    <a v-else-if="contact.type==='email'" :href="'mailto:'+contact.value">
                                                        {{contact.value}}
                                                    </a>
                                                    <a v-else-if="contact.type==='maps'" :href="contact.linkvalue">
                                                        {{contact.value}}
                                                    </a>
                                                    <span v-else>{{contact.value}}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" justify="start">
                                                <v-col cols="2" class="px-2"><v-avatar color="primary"><v-icon dark>mdi-facebook</v-icon></v-avatar></v-col>
                                                <v-col cols="10" class="px-2 text-left"><a target="_blank" :href="conf.contact.fb"><div>La-knett La-knett</div></a></v-col>
                                            </v-row>
                                        
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" class="mb-12 mb-md-0">
                            <v-card flat light>
                                <div id="map" ref="mymap" style="height: 500px"></div>
                                
                                
                            </v-card>                           
                        </v-col>
                        
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
   </v-content>
   <app-footer class="mt-12"/>
</div>
</template>

<script>
import Toolbar from '@/components/comps/Toolbar.vue'
import Footer from '@/components/comps/Footer.vue'

import config from '@/assets/config.json'
import L from 'leaflet'
//import axios from 'axios'

export default {
    components: {
      'app-toolbar' : Toolbar,
      'app-footer' : Footer
    },
    name : 'Contact',
    mounted() {     
        //display map 

        this.map = L.map(this.$refs['mymap'])
        this.map.setView([this.conf.contact.gps.lon, this.conf.contact.gps.lat], 17);
        L.marker([this.conf.contact.gps.lon, this.conf.contact.gps.lat]).addTo(this.map)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?', {
            attribution: 'Map data and imagery &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
            maxZoom: 18 ,
        }).addTo(this.map);


    },
    data() {
        return{
            conf : config,
            contacts: [
                {icon:"mdi-store",value:"Food truck la K-nett", type:"text"},
                {icon:"mdi-email",value: config.contact.email, type:"email"},
                {icon:"mdi-phone",value: config.contact.phone, type: "phone"},
                {icon:"mdi-map-marker",value: config.contact.adresse, linkvalue:config.contact.maps, type:"maps"},
            ],
            map:null

        }
    }
}
</script>